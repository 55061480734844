<script setup>
    import SendRecordForm from '@/components/SendRecordForm.vue';
    import MainPage from '@/components/MainPage.vue';
    import RatingPage from '@/components/RatingPage.vue';
    import LoginPage from '@/components/panel/LoginPage.vue';
    // import '@/components/bootstrap.bundle.min.js'
    import { ref } from 'vue';


    // Data
    const status = ref('form');
    const darkTheme = ref(false);
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        darkTheme.value = true
    }
</script>

<template>
<div :data-bs-theme="darkTheme ? 'dark' : 'light'" class="bg-body" style="min-height: 100vh">
    <nav class="navbar navbar-expand-lg bg-body-tertiary navbar-toggler">
        <div class="container-fluid">
            <img src="@/assets/img/logo.png" class="navbar-brand" v-on:click="status='main'">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="nav nav-pills me-auto mb-2 mb-lg-0">
                    <li class="nav-item" v-on:click="status='main'">
                        <p :class="`nav-link ${status == 'main' ? 'active' : ''}`">Главная</p>
                    </li>
                    <li class="nav-item" v-on:click="status='rating'">
                        <p :class="`nav-link ${status == 'rating' ? 'active' : ''}`">Рейтинг</p>
                    </li>
                    <li class="nav-item" v-on:click="status='form'">
                        <p :class="`nav-link ${status == 'form' ? 'active' : ''}`">Заявка</p>
                    </li>
                </ul>
                <button type="button" :class="`btn btn-${darkTheme ? 'dark' : 'light'} d-flex`"  v-on:click="status='login'">Войти</button>
                <!-- <form class="d-flex" role="idk">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="darkTheme" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault">{{darkTheme ? '🌙' : '☀️'}}</label>
                    </div>
                </form> -->
            </div>
        </div>
    </nav>
  <!-- <div class="pt-3 pb-3">
        <ul class="nav nav-pills justify-content-center">
            <li class="nav-item" v-on:click="status='main'">
                <p :class="`nav-link ${status == 'main' ? 'active' : ''}`">Главная</p>
            </li>
            <li class="nav-item" v-on:click="status='rating'">
                <p :class="`nav-link ${status == 'rating' ? 'active' : ''}`">Рейтинг</p>
            </li>
            <li class="nav-item" v-on:click="status='form'">
                <p :class="`nav-link ${status == 'form' ? 'active' : ''}`">Заявка</p>
            </li>

        </ul>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" v-model="darkTheme" id="flexSwitchCheckDefault">
            <label class="form-check-label" for="flexSwitchCheckDefault">Тёмная тема</label>
        </div>
  </div> -->
  <div class="app pt-2">
      <div v-if="status=='main'">
          <MainPage/>
      </div>
      <div v-if="status=='rating'">
          <RatingPage/>
      </div>
      <div v-if="status=='form'">
          <SendRecordForm/>
      </div>
      <div v-if="status=='login'">
          <LoginPage/>
      </div>
  </div>
  <div class="footer">
      
  </div>
</div>
</template>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');
img{
    cursor: pointer;
}
.nav-link{
    cursor: pointer;
}
.form-check-input{
    cursor: pointer;
}
.nav{
    padding-top: 15px;
}

</style>