<script setup>
/* eslint-disable no-unused-vars */
    import { ref } from 'vue';
    import { rawRecords, getStudentLet, getStudentNum, CLASS_LET, CLASS_NUM, LET_NAMES } from '@/components/const'

    const ratingByNumbers = {
        'c8':  {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c9':  {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c10': {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c11': {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0}
    }
    let sortedRatingByNumbers = {
        'c8':  CLASS_LET,
        'c9':  CLASS_LET,
        'c10': CLASS_LET,
        'c11': CLASS_LET
    }
    const sortBy = ref('byStudents')
    const sortNumBy = ref(8)
    let records = {}
    let sortedRecords = {}

    for (let record of rawRecords) {
        let rName = record.studentName
        let rGrade = record.studentGrade
        if (records[rName] != undefined) {
            ratingByNumbers[`c${getStudentNum(rGrade)}`][getStudentLet(rGrade)]++
            records[rName].count++
            sortedRecords[rName]++
        } else {
            ratingByNumbers[`c${getStudentNum(rGrade)}`][getStudentLet(rGrade)]++
            sortedRecords[rName] = 1
            records[rName] = {count: 1, grade: rGrade}
        }
    }

    const ratingByLets = {
        'П': ratingByNumbers['c8']['П']+ratingByNumbers['c9']['П']+ratingByNumbers['c10']['П']+ratingByNumbers['c11']['П'],
        'Р': ratingByNumbers['c8']['Р']+ratingByNumbers['c9']['Р']+ratingByNumbers['c10']['Р']+ratingByNumbers['c11']['Р'],
        'К': ratingByNumbers['c8']['К']+ratingByNumbers['c9']['К']+ratingByNumbers['c10']['К']+ratingByNumbers['c11']['К'],
        'М': ratingByNumbers['c8']['М']+ratingByNumbers['c9']['М']+ratingByNumbers['c10']['М']+ratingByNumbers['c11']['М'],
        'Б': ratingByNumbers['c8']['Б']+ratingByNumbers['c9']['Б']+ratingByNumbers['c10']['Б']+ratingByNumbers['c11']['Б'],
    }
    for (let num of CLASS_NUM) {
        sortedRatingByNumbers[`c${num}`] = Object.keys(ratingByNumbers[`c${num}`]).sort(function(a,b){return ratingByNumbers[`c${num}`][a]-ratingByNumbers[`c${num}`][b]}).reverse()
    }
    const recordGrades = {}
    for (let num of CLASS_NUM) {
        for (let lett of CLASS_LET) {
            recordGrades[`${num}${lett}`] = ratingByNumbers[`c${num}`][lett]
        }
    }
    let sortedGrades = Object.keys(recordGrades).sort(function(a,b){return recordGrades[a]-recordGrades[b]}).reverse()
    let sortedLets = Object.keys(ratingByLets).sort(function(a,b){return ratingByLets[a]-ratingByLets[b]}).reverse()
    sortedRecords = Object.keys(sortedRecords).sort(function(a,b){return sortedRecords[a]-sortedRecords[b]}).reverse()
</script>

<template>
    <h1 class="text-body text-center mb-4 bt-3">Рейтинг <span class="badge bg-secondary">Предварительно</span></h1>
    <p class="text-body text-center mb-4 bt-3">Рейтинг обновляется раз в 24 часа в 8:30 по Саратовскому времени. Это предворительные данные, точный рейтинг будет доступен только после проверки всех записей КПД учеников.</p>
    <div class="mb-5">
        <h3 class="text-body">Сортировать по:</h3>
        <form class="form-check">
            <input type="radio" class="btn-check" v-model="sortBy" value="byStudents" name="sortBy" id="byStudents" autocomplete="off" checked>
            <label class="btn btn-outline-primary" for="byStudents">По ученикам</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byNumbers" name="sortBy" id="byNumbers" autocomplete="off">
            <label class="btn btn-outline-primary" for="byNumbers">По параллелям</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byLets" name="sortBy" id="byLets" autocomplete="off">
            <label class="btn btn-outline-primary" for="byLets">По направлениям</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byGrades" name="sortBy" id="byGrades" autocomplete="off">
            <label class="btn btn-outline-primary" for="byGrades">По классам</label>
        </form>
    </div>
    <!-- По ученикам -->
    <div v-show="sortBy == 'byStudents'" class="mb-10">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ФИ</th>
                    <th scope="col">Класс</th>
                    <th scope="col">Кол-во записей</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr v-for="(name,i) in sortedRecords" :key="i">
                    <th>{{ i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : i+1}}</th>
                    <td>{{ name.split(' ')[0] }} {{ name.split(' ')[1].split('')[0] }}.</td>
                    <td>{{ records[name].grade }}</td>
                    <td>{{ records[name].count }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- По параллелям -->
    <div v-show="sortBy == 'byNumbers'" class="mb-10">
        <h3 class="text-body">По параллелям:</h3>
        <form class="form-check mb-4 bt-3">
            <input type="radio" class="btn-check" v-model="sortNumBy" :value="8" name="sortNumBy" id="gr8" autocomplete="off" checked>
            <label class="btn btn-outline-primary" for="gr8">Среди 8-ых</label>

            <input type="radio" class="btn-check" v-model="sortNumBy" :value="9" name="sortNumBy" id="gr9" autocomplete="off">
            <label class="btn btn-outline-primary" for="gr9">Среди 9-ых</label>

            <input type="radio" class="btn-check" v-model="sortNumBy" :value="10" name="sortNumBy" id="gr10" autocomplete="off">
            <label class="btn btn-outline-primary" for="gr10">Среди 10-ых</label>

            <input type="radio" class="btn-check" v-model="sortNumBy" :value="11" name="sortNumBy" id="gr11" autocomplete="off">
            <label class="btn btn-outline-primary" for="gr11">Среди 11-ых</label>
        </form>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Класс</th>
                    <th scope="col">Кол-во записей</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr v-for="(grade,i) in sortedRatingByNumbers[`c${sortNumBy}`]" :key="i">
                    <th>{{ i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : i+1}}</th>
                    <td>{{ sortNumBy }}{{ grade }}</td>
                    <td>{{ ratingByNumbers[`c${sortNumBy}`][grade] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- По направлениям -->
    <div v-show="sortBy == 'byLets'" class="mb-10">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Направление</th>
                    <th scope="col">Кол-во записей</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr v-for="(name,i) in sortedLets" :key="i">
                    <th>{{ i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : i+1}}</th>
                    <td>{{ LET_NAMES[name] }}</td>
                    <td>{{ ratingByLets[name] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- По классам -->
    <div v-show="sortBy == 'byGrades'" class="mb-10">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Класс</th>
                    <th scope="col">Кол-во записей</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr v-for="(grade,i) in sortedGrades" :key="i">
                    <th>{{ i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : i+1}}</th>
                    <td>{{ grade }}</td>
                    <td>{{ recordGrades[grade] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style>
</style>