export const CLASS_LET = ['П','Р','К','М','Б']
export const CLASS_NUM = ['8', '9', '10', '11']
export const LET_NAMES = {
    'П': 'Программирование',
    'Р': 'Робототехника',
    'К': 'Культура',
    'М': 'Медицина будущего',
    'Б': 'Биотехнология'
}

export function getStudentLet(grade) {
    if (grade.length == 2) {
        return grade[1]
    }
    return grade[2]
}

export function getStudentNum(grade) {
    if (grade.length == 2) {
        return grade[0]
    }
    return parseInt(`1${grade[1]}`)
}

export function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : undefined;
}

export const rawRecords = [
    {
        studentName: 'Зинкин Александр Олегович',
        studentGrade: '9П'
    },
    {
        studentName: 'Зинкин Александр Олегович',
        studentGrade: '9П'
    },
    {
        studentName: 'Зинкин Александр Олегович',
        studentGrade: '9П'
    },
    {
        studentName: 'Ульянова Наталия Александровна',
        studentGrade: '9П'
    },
    {
        studentName: 'Фамилия Имя Отчество',
        studentGrade: '10М'
    },
    {
        studentName: 'Шинин Дмитрий %',
        studentGrade: '9П'
    },
    {
        studentName: 'Сырник Андрей %',
        studentGrade: '8П'
    },
    {
        studentName: 'Сырков Дмитрий %',
        studentGrade: '8К'
    },
    {
        studentName: 'Сырная Виктория %',
        studentGrade: '9Б'
    },
    {
        studentName: 'Сыркин Виктор %',
        studentGrade: '11Р'
    },
    {
        studentName: 'Сыркин Виктор %',
        studentGrade: '11Р'
    },
    {
        studentName: 'Чекина Анна %',
        studentGrade: '11К'
    },
    {
        studentName: 'Сырок Пирожок %',
        studentGrade: '9Б'
    },
    {
        studentName: 'Сырок Пирожок %',
        studentGrade: '9Б'
    },
    {
        studentName: 'Сырок Пирожок %',
        studentGrade: '9Б'
    }
]

export const STUDENTS = [
    'Агалаков Даниил Сергеевич',
    'Адилева Даяна Сериковна',
    'Амирян Мовсес Каренович',
    'Баженов Иван Дмитриевич',
    'Жданов Дмитрий Олегович',
    'Карпухин Роман Владимирович',
    'Костеев Родион Алексеевич',
    'Курдюмов Сергей Дмитриевич',
    'Майстренко Анастасия Анатольевна',
    'Маруцкая Александра Владимировна',
    'Пономарев Матвей Денисович',
    'Попов Ярослав Евгеньевич',
    'Пчелинцев Никита Дмитриевич',
    'Руденко Иван Владимирович',
    'Рыжова Вера Евгеньевна',
    'Рядинский Кирилл Андреевич',
    'Шереверов Андрей Романович',
    'Юдин Владислав Витальевич',
    'Яковлев Глеб Витальевич',
    'Ярикова Полина Денисовна',
    'Белоглазов Иван Дмитриевич',
    'Быковский Андрей Владиславович',
    'Васильева Мария Романовна',
    'Виноградов Иван Олегович',
    'Витяков Богдан Олегович',
    'Володин Даниил Сергеевич',
    'Горюнов Николай Дмитриевич',
    'Димиденко Дмитрий Юрьевич',
    'Добрынин Захар Андреевич',
    'Журданов Александр Евгеньевич',
    'Зинкин Александр Олегович',
    'Козловских Николай Андреевич',
    'Литовкина Светлана Романовна',
    'Лонский Дмитрий Сергеевич',
    'Ромащенко Денис Александрович',
    'Ульянова Наталия Александровна',
    'Чижов Максим Алексеевич',
    'Шинин Дмитрий Алексеевич',
    'Шкурат Илья Владимирович',
    'Шокин Владислав Павлович',
    'Белоусов Никита Игоревич',
    'Горенкова Елизавета Алексеевна',
    'Зуев Илья Викторович',
    'Иванов Никита Игоревич',
    'Кирсанова Дарья Дмитриевна',
    'Коваленко Ярослав Алексеевич',
    'Козин Юрий Алексеевич',
    'Коковина Милана Юрьевна',
    'Кузяева Ксения Ринатовна',
    'Плишкина Анастасия Алексеевна',
    'Рябинин Роман Андреевич',
    'Сариев Илья Русланович',
    'Сейтхалилова Алина Феузиевна',
    'Усова Анастасия Алексеевна',
    'Хлебникова Полина Алексеевна',
    'Храмов Кирилл Антонович',
    'Шкурат Артем Сергеевич',
    'Шпаковский Никита Сергеевич',
    'Юдаев Владислав Евгеньевич',
    'Яшин Егор Алексеевич',
    'Архипов Иван Сергеевич',
    'Бакаева Дарья Алексеевна',
    'Бакаев Данила Алексеевич',
    'Быков Максим Сергеевич',
    'Вершинина Анна Дмитриевна',
    'Гатигаджиев Руслан Арсенович',
    'Горбунов Григорий Алексеевич',
    'Грыжин Степан Евгеньевич',
    'Дюдяева Маргарита Андреевна',
    'Киреев Артем Витальевич',
    'Корниенко Алина Дмитриевна',
    'Кохан Мария Андреевна',
    'Кочергин Антон Алексеевич',
    'Кузьмин Артем Дмитриевич',
    'Мильто Олег Алексеевич',
    'Мурзагалиева Саида Хабибулловна',
    'Соболев Артем Александрович',
    'Солдаткина Алина Юрьевна',
    'Тяпкина Полина Андреевна',
    'Яковлев Григорий Александрович',
    'Абрамов Платон Андреевич',
    'Афтаев Артем Александрович',
    'Бондарев Егор Александрович',
    'Замятин Степан Сергеевич',
    'Захарова Ксения Александровна',
    'Каримов Самир Навьюллович',
    'Кучко Ксения Викторовна',
    'Матвиевский Иван Александрович',
    'Мачуженко Никита Александрович',
    'Мозоль Иван Денисович',
    'Немченко Алексей Денисович',
    'Перекрестов Владимир Аркадьевич',
    'Перекрестов Роман Аркадьевич',
    'Рахимкулова Лейла Давлетовна',
    'Тюлегенева Елизавета Максимовна',
    'Федоров Матвей Витальевич',
    'Храмова Аксинья Сергеевна',
    'Чернов Алексей Александрович',
    'Шамарина Полина Владимировна',
    'Шубин Глеб Максимович',
    'Астахов Андрей Игоревич',
    'Ашимов Андрей Александрович',
    'Бондаренко Александра Дмитриевна',
    'Грибанов Антон Дмитриевич',
    'Зуев Семен Владимирович',
    'Кузнецов Никита Васильевич',
    'Кулаксыз Кристина Исметовна',
    'Кырчанова Анастасия Александровна',
    'Латыпов Ильяс Ильнурович',
    'Мартынова Александра Михайловна',
    'Мацко Дарья Андреевна',
    'Мачуженко Леона Александровна',
    'Морозова Ева Михайловна',
    'Платонов Артем Иванович',
    'Степанов Андрей Юрьевич',
    'Тулеев Бактияр Тлеккаблович',
    'Федоров Артем Николаевич',
    'Харитонова Алина Сергеевна',
    'Хицинская Лада Вячеславовна',
    'Ширшов Александр Валерьевич',
    'Аленичева Юлия Андреевна',
    'Амиров Сабир Кайратович',
    'Бахвалова Валерия Дмитриевна',
    'Беляев Кирилл Анатольевич',
    'Бирюкова Александра Вадимовна',
    'Вергизов Михаил Анатольевич',
    'Гожев Павел Дмитриевич',
    'Горелов Дмитрий Сергеевич',
    'Григорьев Илья Сергеевич',
    'Еликбаев Руслан Александрович',
    'Ерюшев Евгений Михайлович',
    'Зелепукин Кирилл Алексеевич',
    'Калинин Никита Олегович',
    'Крылосов Максим Андреевич',
    'Лепешкин Егор Вадимович',
    'Литвинова Елизавета Анатольевна',
    'Никитина Елизавета Дмитриевна',
    'Саяпин Артем Алексеевич',
    'Свирин Николай Дмитриевич',
    'Шляхтич Егор Сергеевич',
    'Агапова Александра Анатольевна',
    'Артамонова Ксения Виталиевна',
    'Голованова Ксения Евгеньевна',
    'Гусев Артем Олегович',
    'Дроненко Григорий Денисович',
    'Ерошкин Егор Сергеевич',
    'Жиганова Мария Александровна',
    'Коробов Кирилл Сергеевич',
    'Кулушев Марк Рафаэлевич',
    'Найденов Вадим Александрович',
    'Никитин Семен Евгеньевич',
    'Передумова Арина Вячеславовна',
    'Попова Вероника Эдуардовна',
    'Рассадников Илья Иванович',
    'Ремизов Яромир Петрович',
    'Степаненко Ярослав Дмитриевич',
    'Тарасов Павел Александрович',
    'Трифонова Инна Сергеевна',
    'Хитяев Егор Владимирович',
    'Школьникова Татьяна Васильевна',
    'Антропова Дарья Владимировна',
    'Балабанова Ангелина Евгеньевна',
    'Биктасова Индира Владимировна',
    'Бондаренко Кира Артемовна',
    'Булочева Мария Сергеевна',
    'Григорьев Максим Сергеевич',
    'Гурьянова Милана Александровна',
    'Калинин Иван Олегович',
    'Левашева Полина Александровна',
    'Мамот Дарья Артуровна',
    'Машкова Софья Павловна',
    'Миронова Ксения Сергеевна',
    'Мурзажанова Диана Ринатовна',
    'Найденова Елизавета Александровна',
    'Романова Кира Олеговна',
    'Салтыкова Варвара Андреевна',
    'Сергеева Маргарита Артемьевна',
    'Сулима Александра Вадимовна',
    'Ткачева Антонина Владимировна',
    'Чернова Елизавета Анатольевна',
    'Абрамова Станислава Андреевна',
    'Власенко Полина Ильинична',
    'Герасимов Даниил Романович',
    'Горшкова Елизавета Владиславовна',
    'Жужлова Александра Олеговна',
    'Зимин Глеб Дмитриевич',
    'Ильин Денис Алексеевич',
    'Козина Ольга Вячеславовна',
    'Коновалова Арина Владимировна',
    'Корноухова Анастасия Михайловна',
    'Крылов Никита Кириллович',
    'Мамедов Ратмир Илькинович',
    'Мамот Софья Артуровна',
    'Молоканова Софья Максимовна',
    'Плетнева Софья Андреевна',
    'Потапов Егор Сергеевич',
    'Сахно Полина Романовна',
    'Соколов Святослав Ильич',
    'Федорова Диана Владимировна',
    'Хворова Ирина Дмитриевна',
    'Архипова Алена Вадимовна',
    'Болотникова Софья Ивановна',
    'Добдина Дарья Алексеевна',
    'Дорофеева Ксения Михайловна',
    'Егорова Татьяна Валериевна',
    'Жульмогомбетова Арина Дмитриевна',
    'Зацепилина Олеся Дмитриевна',
    'Ильина Милана Анатольевна',
    'Камышанов Григорий Андреевич',
    'Кульбабаева Адима Альбековна',
    'Морозова Майя Сергеевна',
    'Путилина Елизавета Евгеньевна',
    'Ревзин Иван Борисович',
    'Сидорина Алина Сергеевна',
    'Тулеушова Сабина Наримановна',
    'Харитонова Яна Александровна',
    'Шадрин Никита Сергеевич',
    'Шаталова Дарья Олеговна',
    'Юркина Ксения Александровна',
    'Янковская Полина Петровна',
    'Абраменко Артем Андреевич',
    'Анашкина Аделаида Андреевна',
    'Гильен София Андреевна',
    'Голтаева Анна Витальевна',
    'Давыдова Злата Андреевна',
    'Ищенко Елена Константиновна',
    'Киржева Алина Евгеньевна',
    'Клинова Алина Павловна',
    'Кутузов Тимофей Валерьевич',
    'Макменева Амина Рахимжановна',
    'Максимова Александра Евгеньевна',
    'Мешкова Елизавета Владимировна',
    'Мягкий Андрей Александрович',
    'Панина Мария Александровна',
    'Петрова Анастасия Ивановна',
    'Романов Максим Игоревич',
    'Семенова Мария Александровна',
    'Трачкова Алина Максимовна',
    'Чарикова Полина Алексеевна',
    'Шестопалова Дарья Алексеевна',
    'Алтынбаева Айнура Мендешевна',
    'Бахтина Мария Владимировна',
    'Веремейчик Роман Сергеевич',
    'Доронин Иван Константинович',
    'Иванова Дария Петровна',
    'Илиева Имана Магамедовна',
    'Калашникова Диана Вячеславовна',
    'Каштанова Екатерина Евгеньевна',
    'Котикова Арина Сергеевна',
    'Кулакевич Анастасия Алексеевна',
    'Ломухина Екатерина Сергеевна',
    'Маликов Александр Евгеньевич',
    'Матвеева Софья Викторовна',
    'Оникова Мария Анатольевна',
    'Папкова Екатерина Андреевна',
    'Савин Руслан Александрович',
    'Сирко Николай Андреевич',
    'Халилова Дарья Рустамовна',
    'Чернов Владимир Александрович',
    'Авдеев Иван Сергеевич',
    'Беднова Кристина Георгиевна',
    'Бочкова Алина Дмитриевна',
    'Быстрова Дарья Александровна',
    'Ворнавская Арина Максимовна',
    'Дубицкая Елизавета Александровна',
    'Иванова Марина Кирилловна',
    'Карпушкина Яна Юрьевна',
    'Климашина Варвара Витальевна',
    'Ковалев Александр Дмитриевич',
    'Кокорина Марья Дмитриевна',
    'Лозе Мария Александровна',
    'Невская Елизавета Юрьевна',
    'Орлов Глеб Вячеславович',
    'Павлова Мария Андреевна',
    'Попова Арина Игоревна',
    'Саламаха Виктор Васильевич',
    'Сурмин Глеб Сергеевич',
    'Хлебникова Мария Алексеевна',
    'Чугунова Алена Владимировна',
    'Ажигалиев Назар Берикович',
    'Басманова Мария Максимовна',
    'Быкова Елизавета Алексеевна',
    'Вертянкина Валерия Сергеевна',
    'Возлеева Эмилия Алексеевна',
    'Жиляев Владислав Владимирович',
    'Задорожная Мария Филипповна',
    'Иманов Даниил Нурланович',
    'Клетчук Полина Алексеевна',
    'Лазарев Роман Геннадьевич',
    'Матюхина Вероника Максимовна',
    'Машкова Яна Павловна',
    'Самойлов Алексей Дмитриевич',
    'Титова Виктория Дмитриевна',
    'Ульихина Анастасия Алексеевна',
    'Шумилин Илья Александрович',
    'Аглиулин Артур Валерьевич',
    'Благомыслова Софья Александровна',
    'Бобылев Петр Сергеевич',
    'Васильева Мария Николаевна',
    'Джумагазиева Делия Олеговна',
    'Магамедшарифов Артур Ядулахович',
    'Митяшина Дарья Игоревна',
    'Михайлова Виктория Викторовна',
    'Морозов Артем Юрьевич',
    'Натесова Мария Николаевна',
    'Плешакова Алина Алексеевна',
    'Сердобинцева Виктория Алексеевна',
    'Уткина Александра Сергеевна',
    'Халикова Алиса Александровна',
    'Юдахина Арина Викторовна',
    'Боева Маргарита Дмитриевна',
    'Илюхова Алёна Андреевна',
    'Гурьянова Вероника Александровна',
    'Пискун Артём Андреевич',
    'Байзульдинов Арман Мирланович',
    'Кадрагалиев Тимур Алтынбекович',
    'Тишкова Виктория Сергеевна',
    'Костюкова Софья Владимировна',
    'Шихалиев Эмиль Ниджатович',
    'Хасанов Саид Алибекович',
    'Кашина Маргарита Сергеевна',
    'Аблязова Наталья Вячеславовна',
    'Нарбикова Анисия Жавдятовна',
    'Бармакова Амалия Асльбековна',
    'Камышанова Любовь Андреевна',
    'Актау Зелимхан Данатович',
    'Каттахуджаева Мехрибон Акрамжоновна',
    'Колотилова Светлана Юрьевна',
    'Огаркова Варвара Даниловна',
    'Шигербаева Диана Армановна',
    'Ак Дарья Фикретовна',
    'Блинова Екатерина Александровна',
    'Власенко Анастасия Ильинична',
    'Гершкуль Анастасия Андреевна',
    'Гриневич Кристина Федоровна',
    'Грушин Вячеслав Вячеславович',
    'Денисова Вероника Вячеславовна',
    'Еделькина Елизавета Алексеевна',
    'Жусупова Аделина Аскаровн',
    'Кудряшова Виктория Сергеевна',
    'Ламонова Анастасия Павловна',
    'Максимова Александра Андреевна',
    'Музалевская Виктория Владимировна',
    'Мусатова Виктория Сергеевна',
    'Овчинников Руслан Сергеевич',
    'Петрова Ирина Константиновна',
    'Резникова Елизавета Алексеевна',
    'Семенеева Ангелина Тимуровна',
    'Серова Виктория Андреевна',
    'Шапошников Владимир Евгеньевич',
    'Байбакова Карина Михайловна',
    'Боярская Анна Сергеевна',
    'Власова Ангелина Дмитриевна',
    'Возлеева Глория Алексеевна',
    'Вострухов Андрей Алексеевич',
    'Жулидова Анастасия Михайловна',
    'Краснов Кирилл Сергеевич',
    'Ланцева Вероника Николаевна',
    'Лёвина Ксения Денисовна',
    'Маркова Мария Дмитриевна',
    'Мартыненко Арина Семеновна',
    'Минеева Полина Владиславовна',
    'Нуритдинова Регина Равильевна',
    'Огаркова Ольга Даниловна',
    'Северная Екатерина Дмитриевна',
    'Солодовников Алексей Вячеславович',
    'Тараненко Алина Алексеевна',
    'Хорбин Вадим Денисович',
    'Чувина Наталья Игоревна',
    'Юрьева Ангелина Дмитриевна',
    'Абрамов Андрей Антонович',
    'Агапова Алевтина Александровна',
    'Арстанов Алим Айдосович',
    'Ермакова Виктория Геннадьевна',
    'Захаров Артур Вадимович',
    'Калядин Артем Алексеевич',
    'Карпухина Елена Андреевна',
    'Никулина Виктория Александровна',
    'Новик Анастасия Андреевна',
    'Нукатова Джамиля Уразовна',
    'Овчарова Виолетта Витальевна',
    'Озбаш Джелина Хале',
    'Полухин Артем Романович',
    'Решетова Елисавета Алексеевна',
    'Сивакова Анастасия Владимировна',
    'Сонина Констанция',
    'Тимофеева Елизавета Алексеевна',
    'Фадеева Полина Павловна',
    'Юсупова Ксения Андреевна'
]