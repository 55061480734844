<script setup>
/* eslint-disable */
    import { ref, computed } from 'vue'
    import axios from 'axios'
    import { STUDENTS, CLASS_LET, CLASS_NUM, getCookie } from './const.js'
    const students = ref(STUDENTS)
    const GRADES = ref([])
    for (let num of CLASS_NUM) {
        for (let lett of CLASS_LET) {
            GRADES.value.push(`${num}${lett}`)
        }
    }

    let cookieGrade = getCookie('studentGrade')
    let cookieStudent = getCookie('studentName')
    if (!cookieGrade) cookieGrade = GRADES.value[0]
    if (!cookieGrade) cookieStudent = STUDENTS[0]

    const formDataBlank = { // Это пустой бланк
        eventType: 0,
        eventName: '',
        eventCount: 1,
        eventDate: '',
        eventResult: null,
        fileName: '',
        studentGrade: cookieGrade,
        studentName: cookieStudent,
    }

    let fileProof = ref(null) // Здесь будет храниться файл
    const formData = ref({ // Ну а здесь данные формы
        eventType: 0,
        eventName: '',
        eventCount: 1,
        eventDate: '',
        eventResult: null,
        fileName: '',
        studentGrade: cookieGrade,
        studentName: cookieStudent,
    })

    const hiddenElements = ref({
        successAlert: false,
        errorAlert: false
    })

    // Отправка заполенной формы
    function sendData() {
        let hasErrors = false

        // Сохранение класса и имени студента... ДА КАКОГО СТУДЕНТА??? ученика.
        document.cookie = `studentName=${formData.value.studentName}`
        document.cookie = `studentGrade=${formData.value.studentGrade}`
        
        // Отправка файла
        axios.post('https://sigma.mcxyz.ru/upload.php', {file: fileProof.value}, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            // Файл отправлен на сервер
            formData.value.fileName = response.data
        })
        .catch(error => {
            hasErrors = true
            console.error(error);
        });

        if (hasErrors) {
            hiddenElements.value.errorAlert = true
        } else { // Успех (в жизни)
            hiddenElements.value.successAlert = true
            fileProof.value = null
            Object.assign(formData.value,formDataBlank)
            formData.value.studentGrade = getCookie('studentGrade')
            formData.value.studentName = getCookie('studentName')
        }
    }
    
    // Поиск учеников
    let searchStudent = ref('')
    const foundedStudents = computed( () => {
        let array = students.value.filter( (student) => {
            return student.toLowerCase().indexOf(searchStudent.value.toLowerCase()) !== -1
        })
        return array
    } )
    function searchInput() {
        formData.value.studentName = foundedStudents.value[0]
    }

    //Загрузка файлов
    function photoUpload(e) {
        const [file] = e.target.files
        fileProof.value = file
    }
    let photoPath = computed( () => {
        const file = fileProof.value
        if (file) {
            // const fileName = file.name
            // const fileExt = fileName.split('.')[fileName.split('.').length-1]
            if (file.type.split('/')[0] == 'image') {
                return URL.createObjectURL(file)
            }
            // } else if (fileExt == 'docx' || fileExt == 'doc' || fileExt == 'odt') {
            //     return require('@/assets/img/file_docx.png')
            // } else if (fileExt == 'pdf') {
            //     return require('@/assets/img/file_pdf.png')
            // } else if (fileExt == 'xls' || fileExt == 'xlsx') {
            //     return require('@/assets/img/file_xls.png')
            // }
        }
        return require('@/assets/img/micropic.png')
    } )

    // Переменные классов полей, у которых есть валидация
    let validatorTagsBlank = {
        dateInput: '',
        eventNameInput: '',
        fileInput: '',
        countInput: '',
        eventResultRadio: ''
    }
    let validatorTags = ref({})
    Object.assign(validatorTags.value,validatorTagsBlank)

    // Функция при отправке (проверяет достоверность заполненных полей)
    function trySendData() {
        let isValid = true
        Object.assign(validatorTags.value,validatorTagsBlank)
        let formValues = formData.value
        if (formValues.eventDate == '') {
            validatorTags.value.dateInput = 'is-invalid'
            isValid = false
        }
        if (formValues.eventName == '') {
            validatorTags.value.eventNameInput = 'is-invalid'
            isValid = false
        }
        if (fileProof.value === null || fileProof.value.size > 10485760) {
            validatorTags.value.fileInput = 'is-invalid'
            isValid = false
        }
        if (formValues.eventResult === null) {
            validatorTags.value.eventResultRadio = 'is-invalid'
            isValid = false
        }
        if (formValues.eventCount === '') {
            validatorTags.value.countInput = 'is-invalid'
            isValid = false
        }
        if (!isValid) return
        formValues.eventType = parseInt(formValues.eventType)
        sendData()
    }
</script>

<template>
    <form @submit.prevent="trySendData" class="form-control container pt-5 pb-5">
        <h2>Мероприятие</h2>
        <div class="mb-3">
            <label for="eventType">Тип мероприятия:</label>
            <select id="eventType" v-model="formData.eventType" class="form-select">
                <option value="0">Всероссийская олимпиада школьников (учащиеся)</option>
                <option value="1">Научно-практические конференции (учащиеся)</option>
                <option value="2">Заочные и дистанционные конкурсные мероприятия (учащиеся)</option>
                <option value="3">Очные интеллектуальные конкурсные мероприятия (учащиеся)</option>
                <option value="4">Мероприятия и конкурсы художественно-эстетической направленности (учащиеся)</option>
                <option value="5">Спортивные состязания (учащиеся)</option>
                <option value="6">Социально-значимые мероприятия</option>
                <option value="7">Военно-патриотические, экологические, туристско-краеведческие, поисковые мероприятия (учащиеся)</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="eventName">Название мероприятия:</label>
            <input type="text" id="eventName" :class="`form-control ${validatorTags.eventNameInput}`" v-model="formData.eventName">
        </div>

        <div class="mb-3">
            <label for="eventCount"  aria-describedby="countMoreInfo">Количество участников:</label>
            <input type="number" :class="`form-control ${validatorTags.countInput}`" id="eventCount" v-model="formData.eventCount" placeholder="10" min="1">
            <div id="eventCount" class="form-text">Если в мероприятии участвовал коллектив, группа и т.п., то указывается количество коллективов</div>
        </div>

        <div class="mb-3">
            <label for="eventDate">Дата проведения:</label>
            <input id="eventDate" :class="`form-control ${validatorTags.dateInput}`" type="date" v-model="formData.eventDate">
        </div>

        <div class="mb-3 form-check">
            <label>Результат:</label>
            <div class="input_radio">
            <div><input :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="winner" :value="0" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="winner">Победитель</label></div>
            <div><input :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="prize" :value="1" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="prize">Призёр</label></div>
            <div><input :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="member" :value="2" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="member">Участник</label></div>
            <div><input :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="organizer" :value="3" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="organizer">Организатор (если социально-значимое мероприятие организовывали ВЫ)</label></div>
            
            </div>
        </div>
        <div class="mb-3">
            <label for="photoProof" class="form-label">Подтверждение участия (грамота, сертификат и т.д.) (Не более 10МБ)</label>
            <!-- application/msword, application/vnd.ms-excel, .docx, application/pdf image/*-->
            <input :class="`form-control ${validatorTags.fileInput}`" type="file" name="photoProof" id="photoProof" accept=".jpg, .jpeg, .png" @change="photoUpload">
        </div>
        <div class="mb-3"><img class="rounded documentPreview" :src="photoPath" alt='preview'></div>
        <h2>Ваши данные</h2>
        
        <div class="mb-3">
            <label for="studentGrade">Ваш класс:</label>
            <select class="form-select" id="dropdownGrades" v-model="formData.studentGrade">
                <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="searchStudent">Ваше ФИО:</label>
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1">🔎</span>
                <input id="searchStudent" type="text" class="form-control" @input="searchInput" v-model="searchStudent" placeholder="Поиск...">
            </div>
            </div>
        <div class="mb-3">
            <select id="dropdownStudents" class="form-select" v-model="formData.studentName">
                <option v-for="student in foundedStudents" :key="student" :value="student">{{student}}</option>
            </select>
        </div>
        
        <button type="submit" class="btn btn-primary btn-lg">Отправить на проверку</button>
        <div class="alert alert-success mt-3" role="alert" v-if="hiddenElements.successAlert">Ваша запись успешно отправлена на проверку!</div>
        <div class="alert alert-danger mt-3" role="alert" v-if="hiddenElements.errorAlert">Не удалось отправить форму. Возника ошибка на серверной стороне.</div>
    </form>
</template>

<style>
    .documentPreview {
        max-width: 150px;
        max-height: 150px;
    }
</style>