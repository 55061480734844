<template>
    <div class="main text-body">
        <h1 class="text-center">Добро пожаловать на сайт системы КПД для "Лицея-интерната 64"! </h1>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Наша система КПД представляет собой электронный журнал, который позволяет учителям вести записи о посещаемости занятий и оценках по каждому предмету. Вся информация доступна в режиме реального времени и отображается в персональном кабинете учеников и их родителей. Таким образом, каждый участник образовательного процесса может следить за своей успеваемостью и своевременно реагировать на возможные проблемы.
        <br>
        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Наша система КПД была создана в рамках школьного проекта и призвана улучшить образовательный процесс в нашей школе. Мы гордимся тем, что наш проект получил поддержку руководства школы и был успешно реализован.
        <br>
        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Система КПД для "Лицея-интерната 64" - это простой и удобный инструмент для контроля успеваемости учеников. Она помогает учителям более эффективно организовывать учебный процесс, а ученикам и родителям - быть в курсе текущей успеваемости и своевременно реагировать на возможные проблемы.
        <br>
        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Основные преимущества системы КПД для "Лицея-интерната 64":
        <ul class="list">
        <li>Удобный и простой интерфейс для учителей, учеников и родителей.</li>
        <li>Возможность вести записи о посещаемости занятий и оценках по каждому предмету.</li>
        <li>Вся информация доступна в режиме реального времени.</li>
        <li>Персональные кабинеты для учеников и родителей.</li>
        <li>Возможность своевременно реагировать на возможные проблемы.</li>
        </ul>
        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мы надеемся, что наша система поможет не только улучшить образовательный процесс в "Лицее-интернате 64", но и станет полезным инструментом для других образовательных учреждений. Мы приглашаем вас ознакомиться с нашей системой и использовать ее для повышения качества образования в вашей школе. Спасибо за внимание и добро пожаловать на наш сайт!</p>
    </div>
</template>

<script setup>
</script>

<style>
/* .main {
    text-align: justify;
    padding-top: 15px;
    padding-left: 25%;
    padding-right: 25%;
    line-height: 30px;
    font-family: 'TunnelFront', sans-serif;
}
h1 {
    text-align: center;
    padding-bottom: 15px;
    padding-left: 10%;
    padding-right: 10%;
} */
</style>